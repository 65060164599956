import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
import {
  PUBLIC_SENTRY_DSN,
  PUBLIC_ENV,
} from '$env/static/public';
import { loadAllLocales } from "$i18n/i18n-util.sync";

loadAllLocales();

Sentry.init({
  dsn: PUBLIC_ENV !== 'development' ? PUBLIC_SENTRY_DSN : null,
	environment: PUBLIC_ENV,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
