import { match as about } from "../../../src/params/about.ts";
import { match as activities } from "../../../src/params/activities.ts";
import { match as collection } from "../../../src/params/collection.ts";
import { match as debug } from "../../../src/params/debug.ts";
import { match as directory } from "../../../src/params/directory.ts";
import { match as lang } from "../../../src/params/lang.ts";
import { match as members } from "../../../src/params/members.ts";
import { match as news } from "../../../src/params/news.ts";
import { match as partners } from "../../../src/params/partners.ts";
import { match as projects } from "../../../src/params/projects.ts";

export const matchers = { about, activities, collection, debug, directory, lang, members, news, partners, projects };